@tailwind base;
@tailwind components;
@tailwind utilities;

// Headers

h1 {
  @apply text-4xl;
}
h2 {
  @apply text-3xl;
}
h3 {
  @apply text-2xl;
}

h1,
h2,
h3,
h4,
h5 {
  @apply text-primary font-sans;
}

p {
  @apply mb-2;
}

a, button {
  @apply text-primary font-medium motion-safe:transition duration-200 hover:text-primary-600;
}

strong {
  @apply font-medium;
}

#__next {
  @apply flex flex-col h-screen;
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }